import React from "react"

import Layout from "../components/layout"
import SeO from "../components/seo"
import Header from "../components/header"
import Button from "../components/button"

import { MdFlashOn } from "react-icons/md"

const NotFoundPage = () => (
  <Layout>
      <SeO title="404" />

      <Header 
          title="Lost in translation?"
          subtitle="Erreur 404"
          intro="Malheureusement, cette page n'existe pas (ou plus) sur ce site."
      />

    <div className="container">
      <Button link={"/"} text={"Page d'accueil"} icon={<MdFlashOn />} />
    </div>
  </Layout>
)

export default NotFoundPage
